import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/cute-girl-writing-on-notebook-smiling-at-camera.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'
import BtnOutlined from 'components/button/outlined-button'
import TelLinkSimple from 'components/tel-link-simple'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const PediatricDentistryPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const pd_cta_background = data.pd_cta_background?.childImageSharp?.fluid
  const service_pediatric_1 = data.service_pediatric_1?.childImageSharp?.fluid
  const service_pediatric_2 = data.service_pediatric_2?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Pediatric Dentistry - The Dentists At Gateway Crossing</title>
      <meta name="description" content="We offer many types of pediatric dentistry procedures to help your kids achieve the perfect smile. Browse the page below to learn more. It's easy to schedule" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Pediatric Dentistry - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="We offer many types of pediatric dentistry procedures to help your kids achieve the perfect smile. Browse the page below to learn more. It's easy to schedule" />
      <meta property="og:url" content="https://thedentistsatgc.com/services/pediatric-dentistry/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1333" />
      <meta property="og:image:height" content="2000  " />
      <meta property="og:image:alt" content="cute-girl-writing-on-notebook-smiling-at-camera" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Pediatric Dentistry - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="We offer many types of pediatric dentistry procedures to help your kids achieve the perfect smile. Browse the page below to learn more. It's easy to schedule" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
              PEDIATRIC DENTAL SERVICES MCCORDSVILLE
              </p>
              <h1 className="text-light">
              Pediatric Dentistry
              </h1>
            </div>
            <div className="col-lg-12 text-center">
              <p className="hero-text text-light">We offer many types of pediatric dentistry procedures to help your kids achieve the perfect smile. Browse the page below to learn more. It’s easy to schedule your appointment with us. </p>
              <p className="hero-text text-light">Just click “schedule now” as soon as you’re ready.</p>
            </div>
            <div className="col-lg-12 text-center">
              <a href="#pd-section-3"><button className="btn-rounded"> Learn More </button></a>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
          </div>
      </BackgroundImage>
      <section id ="pd-section-1" className="page-section text-center">
          <Container>
            <div className="col-lg-12">
              <p className ="section-1-text">CHILDREN'S DENTISTRY</p>
              <h2 className ="section-2-header">Children's Smiles Are Important!</h2>
              <p>Your children’s teeth are precious. They need to be taken care of in order to last them a life time. Dr. Vogt can help you to teach your kids to take great care of their teeth. We know how important your kids are to you and that is why we offer a full line of pediatric dental services as well as a kid friendly environment. We want you and your kids to enjoy every trip to the dentist’s office.</p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Happier Kids</h3>
                <p>Your kids won't mind going to the dentist office when they come to see Dr. Vogt. They will leave happy and be happy to come back.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Less Medical Expenses</h3>
                <p>A healthy mouth is a sign of a health body. When your children have better health, you save money.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">Cuter Pictures</h3>
                <p>We know you love your kids and your phone is probably full of their phots. When you make sure your kids teeth are health you're guaranteed better pictures.</p>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Feel More Comfortable</h3>
                <p>Rest easy knowing that we have your back every step of the way. We always make sure you're comfortable with our recommendations.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Gain Valuable Insight</h3>
                <p>Every trip to our office is more than just a dental appointment. We teach you and your kids about oral health and the best preventative care techniques.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">Better Child Development</h3>
                <p>The healthy appearance of your child can play a large role in their psychosocial development. Basically, if they have healthy teeth, they will be accepted more readily by their peers.</p>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-lg-6">
              <Img fluid={service_pediatric_1} alt="Curly Little Girl With a Beautiful Smile" />
            </div>
            <div className="col-lg-6">
              <Img fluid={service_pediatric_2} alt="Five Kids Lying in a Pile on the Grass" />
            </div>
          </Container>
        </section>
        <BackgroundImage
          Tag="section"
          className="pdsection2-bg"
          fluid={pd_cta_background}
          backgroundColor={`#e9f4f8`}
          alt="The Dentists at Gateway Crossing"
        >
          <div className ="col-lg-12" id ="color-overlay">
          </div>
          <section id="pdsection-2" className="page-section">
            <Container>
              <div className="col-lg-6">
                <p className="hero-text">
                KEEP YOUR KIDS HEALTHY!
                </p>
                <h3 className="text-light">
                Don't miss your chance to give your kids the very best teeth!
                </h3>
                <p className="hero-text text-light">
                Your children NEED healthy teeth. Don’t waste another day letting them go without proper dental care. Children shouldn’t have to deal with  poor oral health if they can avoid it. We have affordable  payment options. Call TODAY!
                </p>
                <BtnOutlined url="tel:3176439434" class="btn-rounded" label="(317) 643-9434" />
              </div>
            </Container>
            </section>
          </BackgroundImage>
          <section id ="pd-section-3" className="page-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className ="section-1-text">PEDIATRIC DENTAL DETAILS</p>
              <h2 className ="section-3-header">What is Pediatric Dentistry ?</h2>
             <p>Pediatric Dentistry is a field of dentistry that is focused on children. These little humans only grow up once and it is important to give them the right habits and information as early as possible. It’s a proven fact that people that have healthy teeth and gums live longer happier lives. We know you want the best for your kids and so do we. That’s why we have made your kids our number one priority. </p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Is A Children's Dentist Right For Your Child?</h3>
                <p>Do you have kids? Do they have teeth? Then we say yes! It takes a special type of dentist to work with kids successfully. Dr. Vogt is gentle and patient and knows how to communicate with children. He aims to provide the best dental care while making the experience fun and easy for everyone.</p>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Our Pediatric Dentistry Services</h3>
                <p>Our Pediatric dentistry services include:</p>
                <ul>
                  <li><Link to="/services/preventive-dentistry">Preventive Dentistry</Link></li>
                  <li><Link to= "/services/restorative-dentistry">Restorative Dentistry</Link></li>
                  <li><Link to= "/services/oral-surgery">Oral Surgery</Link></li>
                  <li><Link to= "/services/cosmetic-dentistry">Cosmetic Dentistry</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Are My Child's Pediatric Dental Procedures Covered By My Insurance?</h3>
                <p>Every patient’s situation is different. We would love to discuss payment options with you. Please call our office at <TelLinkSimple />.</p>
              </div>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default PediatricDentistryPage

export const query = graphql`
  query PediatricDentistryPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pd_cta_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    service_pediatric_1: file(name: { eq: "pediatric_1" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    service_pediatric_2: file(name: { eq: "pediatric_2" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        
      }
    }
  }
`
